<template>
	<v-layout>
		<v-flex md8>
			<DatePicker
				:hideTopMargin="hideTopMargin"
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
				:rules="[vrules.required(date, placeholder)]"
				:content-class="{
					required: !date,
				}"
				v-if="required"
			></DatePicker>
			<DatePicker
				v-else
				:hideTopMargin="hideTopMargin"
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
			></DatePicker>
		</v-flex>
		<v-flex md4>
			<v-autocomplete
				:items="timeSlots"
				class=""
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				outlined
				hide-details
				placeholder="Time"
				v-model="time"
				v-on:change="emitValue()"
				:class="{
					required: !time,
					'mt-3': !hideTopMargin,
				}"
			>
			</v-autocomplete>
			<TimePicker
				v-if="false"
				class=""
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				placeholder="Time"
				v-model="time"
				format="HH:mm"
				v-on:change="emitValue()"
				:class="{
					required: !time,
					'mt-3': !hideTopMargin,
				}"
				:minute-interval="minuteInterval"
			></TimePicker>
		</v-flex>
	</v-layout>
</template>
<script>
import { format } from "date-fns";
import DatePicker from "@/view/components/DatePicker";
import MomentJS from "moment-timezone";
//import TimePicker from "@/view/components/TimePicker";
import TimePicker from "vue2-timepicker/src/vue-timepicker.vue";

export default {
	name: "date-time-input",
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			date: null,
			timeSlots: [],
			time: {
				HH: "00",
				mm: "00",
			},
		};
	},
	props: {
		minuteInterval: {
			type: Number,
			default: 1,
		},
		value: {
			type: String,
			default: null,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		DatePicker,
		TimePicker,
	},
	watch: {
		value() {
			this.init();
		},
	},
	methods: {
		init() {
			if (!this.value) {
				return false;
			}
			
			const dateTime = new Date(this.value);
			this.date = format(dateTime, "yyyy-MM-dd");
			
			this.time = format(dateTime, "HH:mm");
			 console.log(this.time,'this.valuesss')
			this.emitValue();
		},
	
		emitValue() {
			if (!this.date) {
				return false;
			}

			const date = new Date(this.date);
const formattedDate = date.toISOString().split('T')[0]; 

// Ensure time is in the format HH:mm (adjust as necessary)
const formattedTime = `${this.time}`;


// Combine the date and time with a space separator
const formattedDateTime = `${formattedDate} ${formattedTime}`;



			this.$emit("input", formattedDateTime);
			this.$emit("change", formattedDateTime);
		},
		

initTimeList() {
    this.timeSlots = [];
    let startTime = MomentJS().startOf("day"); // 00:00
    let endTime = MomentJS().endOf("day");     // 23:59

    while (startTime.isBefore(endTime)) {
        let text = startTime.format("hh:mm A");  // 12-hour format with AM/PM
        let value = startTime.format("HH:mm");   // 24-hour format
        this.timeSlots.push({ text, value });
        startTime.add(30, "minutes");
    }
}
	},
	mounted() {
		this.init();
		this.initTimeList();
	},
};
</script>
