var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[_c('v-flex',{attrs:{"md8":""}},[(_vm.required)?_c('DatePicker',{attrs:{"hideTopMargin":_vm.hideTopMargin,"hide-details":_vm.hideDetails,"readonly":_vm.readonly,"disabled":_vm.disabled,"loading":_vm.loading,"label":_vm.label,"id":_vm.id,"min-date":_vm.minDate,"placeholder":_vm.placeholder,"rules":[_vm.vrules.required(_vm.date, _vm.placeholder)],"content-class":{
				required: !_vm.date,
			}},on:{"change":function($event){return _vm.emitValue()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_c('DatePicker',{attrs:{"hideTopMargin":_vm.hideTopMargin,"hide-details":_vm.hideDetails,"readonly":_vm.readonly,"disabled":_vm.disabled,"loading":_vm.loading,"label":_vm.label,"id":_vm.id,"min-date":_vm.minDate,"placeholder":_vm.placeholder},on:{"change":function($event){return _vm.emitValue()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-flex',{attrs:{"md4":""}},[_c('v-autocomplete',{class:{
				required: !_vm.time,
				'mt-3': !_vm.hideTopMargin,
			},attrs:{"items":_vm.timeSlots,"readonly":_vm.readonly,"disabled":_vm.disabled,"loading":_vm.loading,"label":_vm.label,"id":_vm.id,"outlined":"","hide-details":"","placeholder":"Time"},on:{"change":function($event){return _vm.emitValue()}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),(false)?_c('TimePicker',{class:{
				required: !_vm.time,
				'mt-3': !_vm.hideTopMargin,
			},attrs:{"readonly":_vm.readonly,"disabled":_vm.disabled,"loading":_vm.loading,"label":_vm.label,"id":_vm.id,"placeholder":"Time","format":"HH:mm","minute-interval":_vm.minuteInterval},on:{"change":function($event){return _vm.emitValue()}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }