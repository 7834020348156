<template>
	<div v-if="drawer">
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="700"
			right
			temporary
			stateless
			style="z-index: 200"
		>
			<template v-slot:prepend>
				<div class="drawer-wrapper">
					<v-flex
						class="py-4 px-5 border-bottom drawer-heade d-flex justify-space-between"
						style="background-color: #f57c00"
					>
						<div class="font-level-3-bold text-white">{{ titleStatus }} Meeting</div>
						<div class="bg-white" style="width: 200px; border-radius: 50px">
							<AutoCompleteInput
								style="border-radius: 50px !important"
								hideTopMargin
								dense
								id="related-to"
								hide-details
								placeholder="Related To"
								:items="relatedList"
								:disabled="pageLoading"
								:loading="pageLoading"
								item-text="label"
								v-model="meeting.related_to"
								item-value="value"
								@change="getRelatedField(false)"
							>
							</AutoCompleteInput>
						</div>
					</v-flex>
				</div>
			</template>
			<div class="drawer-wrapper">
				<v-form
					ref="meetingForm"
					v-model.trim="formValid"
					lazy-validation
					@submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-1" v-if="related == null && false">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Related To</label>
							</div>

							<RadioInputCustom
								v-model="meeting.related_to"
								@change="getRelatedField(false)"
								row
								color="blue darken-4"
								:items="relatedList"
								hide-details
							/>
						</div>

						<template v-if="meeting.related_to != 'internal'">
							<div
								class="mb-2"
								v-if="meeting.related_to != null && related == null && meeting.related_to != 'internal'"
							>
								<v-row class="mb-3">
									<v-col md="3" class="my-auto text-end">
										<label class="fw-500 text-capitalize">
											<!-- <template v-if="meeting.related_to == 'lead'">Company Name</template> -->
											<template v-if="meeting.related_to == 'opportunity'">Opportunity </template>
											<template v-if="meeting.related_to == 'contact'">Contact </template>
											<template v-if="meeting.related_to == 'lead'">Company Name</template>
											<template v-else-if="meeting.related_to == 'task'">Task </template>
											<template v-else-if="meeting.related_to == 'others'">Others </template>

											<template v-else-if="meeting.related_to == 'customer'">Customer </template>
										</label>
									</v-col>
									<!-- <div v-if="meeting.related_to != null">
								<v-autocomplete
									:items="dataLists"
									item-text="text"
									item-value="value"
									depressed
									hide-details
									outlined
									v-model="meeting.related_id"
									v-on:change="meetingLocation(meeting.related_id)"
								></v-autocomplete>
							</div> -->

									<v-col md="9">
										<div>
											<div v-if="meeting.related_to != null" class="d-flex">
												<v-autocomplete
													:items="dataLists"
													item-text="text"
													item-value="value"
													:placeholder="
														meeting.related_to == 'opportunity'
															? 'Select Lead'
															: meeting.related_to == 'customer'
															? 'Select Customer'
															: 'Select Task'
													"
													depressed
													hide-details
													outlined
													:loading="pageLoading"
													v-model="meeting.related_id"
													:class="[
														{
															required: !meeting.related_id,
															'pr-2': meeting.related_to == 'opportunity',
														},
													]"
													@change="meetingLocation(meeting.related_id)"
												>
													<template #selection="{ item }">
														<div class="d-flex align-center">
															<span class="fw-500 text-uppercase">{{ item.text }}</span>
															<v-chip
																v-if="item.lead_barcode || item?.defaultContact?.customer_name"
																label
																x-small
																class="px-1 ml-3"
																color="green"
																dark
															>
																<span class="fs-11">{{
																	item.lead_barcode || item?.defaultContact?.customer_name
																}}</span>
															</v-chip>
															<v-chip
																v-if="item?.priority"
																:color="priority_color[item?.priority]"
																dark
																x-small
																label
																size="10"
																class="ml-1 fw-500 px-1 text-uppercase"
															>
																<span class="fs-11">{{ item?.priority }}</span>
																<!-- <span :class="`${priority_color[item?.priority]}--text`" class="fw-600 text-uppercase fs-10">{{ item?.priority }}</span> -->
															</v-chip>
															<v-chip
																v-if="item?.related_to"
																color="primary"
																dark
																x-small
																label
																size="10"
																class="ml-1 fw-500 px-1 text-uppercase"
															>
																<span class="fs-11">{{ item?.related_to }}</span>
															</v-chip>
														</div>
													</template>
													<template #item="{ item }">
														<template>
															<div>
																<span
																	v-if="item?.priority"
																	:class="`${priority_color[item?.priority]}--text`"
																	class="fw-700 text-uppercase fs-10"
																	>{{ item?.priority }}</span
																>

																<div class="d-flex align-center text-uppercase fw-500 py-1">
																	{{ item.text }}
																	<v-chip
																		v-if="item.lead_barcode"
																		label
																		x-small
																		class="px-1 ml-3"
																		color="green"
																		dark
																	>
																		<span class="fs-11">{{ item.lead_barcode }}</span>
																	</v-chip>
																	<v-chip
																		v-if="item?.related_to"
																		color="primary"
																		dark
																		x-small
																		label
																		size="10"
																		class="ml-1 fw-500 px-1 text-uppercase"
																	>
																		<span class="fs-10">{{ item?.related_to }}</span>
																	</v-chip>
																</div>
																<div v-if="item?.defaultContact?.customer_name" class="fw-500 text-muted mr-3">
																	{{ item?.defaultContact?.customer_name }}
																</div>

																<span
																	v-if="item?.customer_name || item?.defaultContact?.email"
																	class="fw-500 text-muted"
																>
																	<v-icon color="primary" v-if="item?.defaultContact?.email" size="16"
																		>mdi-email</v-icon
																	>
																	{{ item?.customer_name || item?.defaultContact?.email }}</span
																>
																<span v-if="item?.defaultContact?.phone" class="fw-500 text-muted">
																	&nbsp; <v-icon color="green" size="16">mdi-phone</v-icon>
																	{{ item?.defaultContact?.phone }}</span
																>
															</div>
														</template>
													</template>
												</v-autocomplete>
												<v-icon
													v-if="meeting.related_to == 'lead' || related == 'lead'"
													v-on:click="CreateLeadDialog = true"
													class="primary--text"
												>
													mdi-cog
												</v-icon>
											</div>
										</div>
									</v-col>
								</v-row>
							</div>
							<!--Meeting Option-->
							<v-row class="mt-3" v-if="meetingOption.label && meetingOption.label != 'internal'">
								<v-col md="3"></v-col>
								<v-col md="9">
									<div class="mt-1 bg-quo pb-3 custom-border-bottom" style="margin-bottom: 0.8rem">
										<table width="100%" class="custom-list-tabl">
											<tr>
												<td class="py-0">
													<v-chip
														class="text-capitalize fw-500 mb-1 px-1"
														color="blue"
														x-small
														dark
														label
														v-if="meetingOption.label == 'lead'"
													>
														Opportunity
													</v-chip>
													<v-chip
														class="text-capitalize fw-500 mb-1 px-1"
														color="blue"
														x-small
														dark
														label
														v-else-if="meetingOption.label == 'customer'"
													>
														Company
													</v-chip>
													<v-chip
														class="text-capitalize fw-500 mb-1 px-1"
														color="blue"
														x-small
														dark
														label
														v-else
													>
														{{ meetingOption.label }}
													</v-chip>
												</td>
												<td class="py-0"></td>
											</tr>
											<tr v-if="meetingOption.lead.company && meetingOption.label == 'lead'">
												<!-- <td class="py-0">Company:</td> -->
												<td class="py-0 fs-16">
													{{ meetingOption.lead.company }}
												</td>
											</tr>
											<tr v-if="meetingOption.lead.phone && meetingOption.label == 'lead'">
												<!-- <td class="py-0">Phone:</td> -->
												<td class="py-0 cursor-pointer" @click="clickAndCopy(meetingOption.lead.phone)">
													<v-icon size="16" color="green" class="mt-0 pt-0">mdi-phone</v-icon>
													{{ meetingOption.lead.phone }}
												</td>
											</tr>
											<tr v-if="meetingOption.lead.email && meetingOption.label == 'lead'">
												<!-- <td class="py-0">Email:</td> -->
												<td class="py-0 cursor-pointer" @click="clickAndCopy(meetingOption.lead.email)">
													<v-icon size="16" color="primary" class="mt-0 pt-0">mdi-email</v-icon>
													{{ meetingOption.lead.email }}
												</td>
											</tr>
											<!-- <tr v-if="meetingOption.customer.company && meetingOption.label == 'customer'">
												<td class="fs-16 text-uppercase">
													<span class="py-0 text-uppercase">{{ meetingOption.customer.company }}</span>
												</td>
												<td>
													<v-chip
														v-if="meetingOption.customer.barcode"
														label
														x-small
														class="px-1 ml-3"
														color="green"
														dark
													>
														<span class="fs-10">{{ meetingOption.customer.barcode }}</span>
													</v-chip>
												</td>
											</tr> -->
											<!-- <tr v-if="meetingOption.customer.barcode && meetingOption.label == 'customer'">
												<td class="py-0">
													<span>Cust ID #:</span> <span>{{ meetingOption.customer.barcode }}</span>
												</td>
											</tr> -->
											<!-- 										<tr>
											<td class="py-0 cursor-pointer" @click="clickAndCopy(meetingOption.lead.phone)">
												<v-icon size="16" color="green" class="mt-0 pt-0">mdi-phone</v-icon>
												{{ meetingOption }}
											</td>
										</tr>
										<tr>
											<td class="py-0 cursor-pointer" @click="clickAndCopy(meetingOption.lead.email)">
												<v-icon size="16" color="primary" class="mt-0 pt-0">mdi-email</v-icon>
												{{ meetingOption.lead.email }}
											</td>
										</tr> -->
											<tr v-if="meetingOption.customer.website && meetingOption.label == 'customer'">
												<td class="py-0">
													<a :href="meetingOption.customer.website" target="_blank">
														<span class="cursor-pointer d-blue--text">{{ meetingOption.customer.website }}</span>
													</a>
												</td>
											</tr>
											<tr v-if="meetingOption.task.related_to && meetingOption.label == 'task'">
												<td class="py-0">
													<span>Related To:</span> <span>{{ meetingOption.task.related_to }}</span>
												</td>
											</tr>
											<tr v-if="meetingOption.task.start_date && meetingOption.label == 'task'">
												<td class="py-0">
													<span>Start Date:</span> <span>{{ meetingOption.task.start_date }}</span>
												</td>
											</tr>
											<tr v-if="meetingOption.task.due_date && meetingOption.label == 'task'">
												<td class="py-0">
													<span>Due Date:</span> <span>{{ meetingOption.task.due_date }}</span>
												</td>
											</tr>
											<tr v-if="meetingOption.task.priority && meetingOption.label == 'task'">
												<td class="py-0">
													<span>Priority:</span> <span>{{ meetingOption.task.priority }}</span>
												</td>
											</tr>
										</table>
									</div>
									<div class="mt-1 bg-quo pb-3" style="margin-bottom: 0.8rem">
										<table
											width="100%"
											class="mt-2 custom-border-bottom"
											v-if="
												(meetingOption.label == 'lead' || meetingOption.label == 'customer') &&
												(getValue(meetingOption, 'address.address_1') ||
													getValue(meetingOption, 'address.address_2') ||
													getValue(meetingOption, 'address.postal_code') ||
													getValue(meetingOption, 'address.country') ||
													getValue(meetingOption, 'address.unit_number'))
											"
										>
											<tr>
												<td class="py-0 d-flex">
													<span class="mr-2">
														<v-icon color="green" small>mdi-map-marker</v-icon>
													</span>
													<label class="fw-500">
														<template v-if="getValue(meetingOption, 'address.address_1')">
															{{ getValue(meetingOption, "address.address_1") }},
														</template>
														<template v-if="getValue(meetingOption, 'address.address_2')">
															{{ getValue(meetingOption, "address.address_2") }},
														</template>
														<template v-if="getValue(meetingOption, 'address.unit_number')">
															{{ getValue(meetingOption, "address.unit_number") }} <br />
														</template>
														<template v-if="getValue(meetingOption, 'address.postal_code')">
															{{ getValue(meetingOption, "address.postal_code") }}<br />
														</template>
														<template v-if="getValue(meetingOption, 'address.country')">
															{{ getValue(meetingOption, "address.country") }}<br />
														</template>
													</label>
												</td>
											</tr>
										</table>
										<table
											width="100%"
											class="mt-2 custom-border-bottom"
											v-if="meetingOption.label == 'task' && getValue(meetingOption, 'task.lead_barcode')"
										>
											<tr>
												<td class="py-0 d-flex">
													<template v-if="getValue(meetingOption, 'task.lead_barcode')">
														{{ getValue(meetingOption, "task.lead_barcode") }}<br />
													</template>
													<template v-if="getValue(meetingOption, 'task.lead_company')">
														{{ getValue(meetingOption, "task.lead_company") }}<br />
													</template>
												</td>
											</tr>
										</table>
									</div>
								</v-col>
							</v-row>
						</template>
						<!-- meeting Option-->
						<RadioInputCustom
							v-if="false"
							@change="getMeetingTypeData($event)"
							v-model="meeting.meeting_type"
							row
							color="blue darken-4"
							hide-details
							:items="InternalMeetingTypeList"
						/>

						<div class="mb-3" v-if="getMeetingData && getMeetingData.lead_customer_name">
							<div class="d-flex pt-1">
								<label class="fw-500">Person Incharge :</label>
								<span class="ml-3">
									<b>{{ getMeetingData.lead_customer_name }}</b></span
								>
							</div>
						</div>

						<v-row class="mb-3">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500 required">Title</label>
							</v-col>
							<v-col md="9">
								<v-text-field
									depressed
									hide-details
									outlined
									placeholder="Title"
									v-model="meeting.title"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[
										vrules.required(meeting.title, 'Title'),
										vrules.maxLength(meeting.title, 'Title', 30),
									]"
									:class="{
										required: !meeting.title,
									}"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3" v-if="false">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500">Contact Name</label>
							</v-col>
							<v-col md="9">
								<v-text-field
									depressed
									hide-details
									outlined
									placeholder="Contact Name"
									v-model="meeting.contact_name"
									:disabled="pageLoading"
									:loading="pageLoading"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="mb-3">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500">Meeting Type</label>
							</v-col>
							<v-col md="9">
								<AutoCompleteInput
									hideTopMargin
									dense
									id="meeting-type"
									hide-details
									placeholder="Select Meeting"
									:items="InternalMeetingTypeList"
									:disabled="pageLoading"
									:loading="pageLoading"
									item-text="label"
									v-model="meeting.meeting_type"
									@change="getMeetingTypeData($event)"
									item-value="value"
								>
								</AutoCompleteInput>
							</v-col>
						</v-row>

						<v-row class="mb-3">
							<v-col md="3" class="text-end my-auto">
								<!-- <label class="fw-500 required">Meeting Time </label> -->
							</v-col>
							<v-col md="3">
								<label class="fw-500 required">Date</label>
								<DateTimePicker
									v-if="false"
									dense
									id="reminder-start-date"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Date"
									v-model="meeting.start_from"
									v-on:change="get_reminder_values()"
									:rules="[vrules.required(meeting.start_from, 'Date')]"
									:class="{ required: !meeting.start_from }"
									hideTopMargin
								></DateTimePicker>
								<DatePicker
									hideTopMargin
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-from"
									placeholder="Date"
									v-model="meeting.start_from"
									v-on:change="durationTimedate"
									:rules="[vrules.required(meeting.start_from, 'Date')]"
									:class="{
										required: !meeting.start_from,
									}"
								></DatePicker>
							</v-col>
							<v-col md="3">
								<div class="">
									<label class="fw-500 required">Start Time</label>
									<TimePickerNew
										v-model="meeting.start_time"
										v-on:change="durationTimedate"
										placeholder="Time"
										hide-details
									>
									</TimePickerNew>
									<!-- <v-autocomplete
										:items="timeSlots"
										
										depressed
										hide-details
										placeholder="Time"
										outlined
										v-model="meeting.start_time"
										v-on:change="durationTimedate"
									>
									</v-autocomplete> -->
								</div>
							</v-col>
							<v-col md="3">
								<label class="fw-500 required">Duration </label>
								<v-autocomplete
									:items="durationList"
									item-text="value"
									item-value="value"
									depressed
									hide-details
									placeholder="Duration"
									outlined
									v-model="meeting.duration"
									v-on:change="durationTimedate"
									:rules="[vrules.required(meeting.duration, 'Duration')]"
									:class="{
										required: !meeting.duration,
									}"
								></v-autocomplete>
							</v-col>
							<v-col md="3" class="text-end my-auto"> </v-col>
							<v-col md="9">
								<div class="">
									This meeting will take place on the
									<b>{{ formattedDate }} {{ meeting.start_time }} until {{ meeting.end_time }}</b>
								</div>
							</v-col>
						</v-row>
						<div class="mb-3" v-if="false">
							<div class="d-flex">
								<div class="w-50 pr-3">
									<label class="fw-500 required">Start Time</label>
									<TimePicker
										class=""
										:disabled="pageLoading"
										:loading="pageLoading"
										id="start time"
										placeholder="Start Time"
										v-model="meeting.start_time"
										format="HH:mm"
										v-on:change="durationTimedate()"
										:class="{
											required: !meeting.start_time,
										}"
										:minute-interval="15"
									></TimePicker>

									<!-- <v-autocomplete
										:items="timeList"
										item-text="value"
										item-value="value"
										depressed
										hide-details
										placeholder="Time"
										outlined
										v-model="meeting.start_time"
										v-on:change="durationTimedate"
									>
									</v-autocomplete> -->
								</div>
								<div class="w-50">
									<label class="fw-500 required">End Time</label>
									<div class="w-100">
										<div class="mt-2 fw-400" style="font-size: 14px">
											{{ meeting.end_time }}
										</div>
										<!-- <v-autocomplete
											:items="timeList"
											readonly
											item-text="value"
											item-value="value"
											depressed
											hide-details
											placeholder="Time"
											outlined
											v-model="meeting.end_time"
										>
										</v-autocomplete> -->
									</div>
								</div>
							</div>
						</div>
						<template v-if="meeting.id == null">
							<!-- <v-row class="mb-3">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Reminder Type</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hideTopMargin
										dense
										id="reminder-type"
										hide-details
										placeholder="Select Reminder Type"
										:items="reminder_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="meeting.reminder_type"
										v-on:change="get_reminder_values()"
										item-value="value"
									>
									</AutoCompleteInput>
								</v-col>
							</v-row> -->
							<v-row class="mb-3">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500 required">Set Reminder</label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										hideTopMargin
										dense
										id="reminder"
										hide-details
										placeholder="Select Reminder"
										:items="reminderList"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="meeting.set_reminder"
										item-value="value"
									>
									</AutoCompleteInput>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="meeting.set_reminder == 'others'">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500 required">Reminder Start Date</label>
								</v-col>
								<v-col md="9">
									<DateTimePicker
										hideTopMargin
										dense
										id="meeting-start-date"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="meeting.reminder_start_date"
										:placeholder="
											meeting.reminder_type != 1
												? 'Select Reminder Start Date...'
												: 'Select Reminder Start Date...'
										"
										v-on:change="get_reminder_values()"
										:rules="[
											vrules.required(
												meeting.reminder_start_date,
												meeting.reminder_type != 1 ? 'Reminder Start Date' : 'Reminder Start Date'
											),
										]"
										:class="{ required: !meeting.reminder_start_date }"
										:minute-interval="15"
									></DateTimePicker>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="meeting.reminder_type != 1">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Frequency</label>
								</v-col>
								<v-col md="9">
									<TextInput
										v-if="false"
										dense
										id="meeting-frequency"
										hide-details
										type="number"
										placeholder="Enter Frequency..."
										:disabled="pageLoading"
										:loading="pageLoading"
										class="mt-0"
										v-model="meeting.reminder_frequency"
										v-on:keyup="get_reminder_values()"
									>
									</TextInput>

									<v-text-field
										type="Number"
										v-mask="'####'"
										depressed
										hide-details
										outlined
										placeholder="Enter Frequency..."
										v-model="meeting.reminder_frequency"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-on:keyup="get_reminder_values()"
										@wheel="$event.target.blur()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="meeting.reminder_type != 1">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Reminder End Date</label>
								</v-col>
								<v-col md="9">
									<div style="font-size: 14px" class="mt-1">{{ reminderEndFormatted }}</div>
								</v-col>
							</v-row>
							<div class="mb-3 pt-1" v-if="false">
								<div class="pt-1 d-flex">
									<label class="fw-500">Reminder for</label>
								</div>

								<RadioInputCustom
									class="mt-0"
									v-model="meeting.callType"
									row
									:items="[
										{
											label: 'Call',
											color: 'green',
											value: 'call',
										},
										{
											label: 'Email',
											color: 'red',
											value: 'email',
										},
									]"
								/>
							</div>
						</template>

						<v-row class="mb-3" v-if="meeting.meeting_type == 'other2'">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500">Country</label>
							</v-col>
							<v-col md="9">
								<v-autocomplete
									v-model="country"
									:items="countryList"
									:menu-props="{ bottom: true, offsetY: true }"
									outlined
									hide-details
									placeholder="Country"
									color="blue darken-4"
									item-value="value"
									item-text="text"
									@change="countryCheck($event), (resetSearch = true)"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3" v-if="meeting.meeting_type == 'other2'">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500" style="margin-bottom: -5px !important">Search Address</label>
							</v-col>
							<v-col md="9">
								<SearchAddress
									variant="outlined"
									density="compact"
									hide-details
									:country="country"
									:country-code="country_code"
									@update:address="setAddress"
									:label="'Search Address'"
									:placeholder="'Search Address'"
								/>
							</v-col>
						</v-row>
						<v-row v-if="meeting.meeting_type == 'other2' && false">
							<v-col md="12" style="margin-bottom: -1rem">
								<div class="">
									<div class="d-flex pt-1">
										<label class="fw-500">Search Address</label>
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row v-if="meeting.meeting_type == 'other2' && false">
							<v-col md="4">
								<v-autocomplete
									v-model="country"
									:items="countryList"
									:menu-props="{ bottom: true, offsetY: true }"
									outlined
									hide-details
									placeholder="Country"
									class="filterSelect mt-2"
									content-class="filterSelectContent"
									item-value="value"
									item-text="text"
									@change="countryCheck($event), (resetSearch = true)"
								>
								</v-autocomplete>
							</v-col>
							<v-col md="4">
								<div class="mb-0">
									<SearchAddress
										variant="outlined"
										density="compact"
										hide-details
										:country="country"
										:country-code="country_code"
										@update:address="setAddress"
										:label="'Search Address'"
										:placeholder="'Search Address'"
									/>
								</div>
								<!-- :searching-address="searchingAddress" -->
							</v-col>
							<v-col md="4"> </v-col>
						</v-row>

						<v-row class="mb-0" v-if="meeting.meeting_type != 'zoom'">
							<v-col md="3" class="text-end my-auto">
								<label class="fw-500 required">Location / URL</label>
							</v-col>
							<v-col md="9">
								<div class="mb-3" v-if="meeting.related_to != null && meeting.related_to == 'lead'">
									<div v-if="meeting.related_to != null">
										<!-- && meeting.default_location_url -->
										<v-text-field
											depressed
											hide-details
											outlined
											:readonly="
												(meeting.meeting_type == 'client' && meeting.pre_location) ||
												meeting.meeting_type == 'bthrust'
													? true
													: false
											"
											placeholder="Location / URL"
											v-model="meeting.location"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(meeting.location, 'Meeting Location')]"
											:class="{
												required: !meeting.location,
											}"
										></v-text-field>
									</div>
								</div>
								<div class="mb-3" v-if="meeting.related_to != null && meeting.related_to == 'customer'">
									<div v-if="meeting.related_to != null">
										<v-text-field
											depressed
											hide-details
											outlined
											:readonly="
												(meeting.meeting_type == 'client' && meeting.pre_location) ||
												meeting.meeting_type == 'bthrust'
													? true
													: false
											"
											placeholder="Location / URL"
											v-model="meeting.location"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(meeting.location, 'Location / URL')]"
											:class="{
												required: !meeting.location,
											}"
										></v-text-field>
									</div>
								</div>
								<div class="mb-3" v-if="meeting.related_to != null && meeting.related_to == 'task'">
									<div v-if="meeting.related_to != null">
										<v-text-field
											depressed
											hide-details
											outlined
											:readonly="
												(meeting.meeting_type == 'client' && meeting.pre_location) ||
												meeting.meeting_type == 'bthrust'
													? true
													: false
											"
											placeholder="Location / URL"
											v-model="meeting.location"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(meeting.location, 'Location / URL')]"
											:class="{
												required: !meeting.location,
											}"
										></v-text-field>
									</div>
								</div>
								<div v-else-if="meeting.related_to == 'task' || meeting.related_to == 'internal'">
									<v-text-field
										depressed
										hide-details
										outlined
										placeholder="Location / URL"
										:readonly="meeting.meeting_type == 'bthrust' ? true : false"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="meeting.location"
										:rules="[vrules.required(meeting.location, 'Location')]"
										:class="{
											required: !meeting.location,
										}"
									></v-text-field>
								</div>
							</v-col>
						</v-row>
						<v-row class="mb-3">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500 required">Assigned To</label>
							</v-col>
							<v-col md="9">
								<!-- :readonly="currentUser.role != 1 && currentUser.role != 4" -->
								<v-autocomplete
									:items="userList"
									item-text="display_name"
									item-value="id"
									depressed
									hide-details
									outlined
									placeholder="Attendees"
									hide-selected
									class="cuatom-drop"
									multiple
									color="blue darken-4"
									small-chips
									deletable-chips
									v-model="meeting.attendees"
									:disabled="pageLoading || (currentUser.role != 1 && currentUser.role != 4)"
									:loading="pageLoading"
									:rules="[vrules.required(meeting.attendees, 'Assigned To')]"
									:class="{
										required: !meeting?.attendees?.length,
									}"
								>
									<template v-slot:selection="data">
										<v-chip
											small
											v-bind="data.attrs"
											:input-value="data.selected"
											close
											@click="data.select"
											@click:close="remove(data.item)"
										>
											<v-avatar left>
												<img v-if="data.item.profile_img" :src="data.item.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											{{ data.item.display_name }}
										</v-chip>
									</template>
									<template v-slot:item="{ item }">
										<div class="py-1 d-flex align-center">
											<v-avatar size="36">
												<img v-if="item.profile_img" :src="item.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											<div class="ml-3">
												<div class="fw-600 text-uppercase">{{ item.display_name }}</div>
												<div class="text-muted">{{ item.email }}</div>
											</div>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 py-0" v-if="!filteredArray.length == 0">
							<v-col md="3" class=""> </v-col>
							<v-col md="9" class="pb-0">
								<div class="v-avatar-group d-flex text-center">
									<template v-for="(team, index) in filteredArray">
										<v-badge class="mr-2" small color="grey" overlap :key="index">
											<template v-slot:badge
												><v-icon x-small @click="remove(team)">mdi-close</v-icon></template
											>
											<span>
												<!-- <v-avatar size="32" v-bind="attrs" v-on="on"> -->
												<v-avatar size="32">
													<img
														:src="
															team?.profile_img
																? team?.profile_img
																: 'https://ams-dev.businessthrust.com//media/misc/no_photo_found.png'
														"
													/>
												</v-avatar>
												<div style="font-size: 10px" :key="index">{{ team.display_name }}</div>
											</span>
										</v-badge>
									</template>
								</div>
							</v-col>
						</v-row>

						<!-- <div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">MOM</label>
							</div>
							<v-textarea depressed hide-details outlined v-model="meeting.mom"></v-textarea>
						</div> -->

						<v-row class="">
							<v-col md="3" class="my-auto text-end">
								<label class="fw-500">Attachment</label>
							</v-col>
							<v-col md="9">
								<template v-for="(file, index) in files">
									<div class="d-flex mb-2" :key="index">
										<div class="w-40 pr-3" style="overflow: hidden">
											<v-file-input
												:id="`document-file-${index}`"
												placeholder="Select File"
												outlined
												class=""
												prepend-icon=""
												prepend-inner-icon="mdi-attachment"
												hide-details
												v-model="file.file"
												v-on:change="updateFile(index, $event)"
												v-on:click:clear="updateFile(index, $event)"
											></v-file-input>
										</div>
										<div class="w-40 pr-3">
											<TextInput
												hideTopMargin
												:id="`document-name-${index}`"
												v-model="file.name"
												hide-details
												placeholder="File Name"
												:suffix="file.suffix"
											></TextInput>
										</div>
										<div class="w-10 pr-3">
											<v-btn
												:disabled="files.length < 2"
												v-on:click="removeFile(index)"
												color="red lighten-1 white--text"
												class=""
												tile
												depressed
												small
												outlined
												min-width="30"
												width="30"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</div>
										<div v-if="index == files.length - 1" class="w-10 pr-3">
											<v-btn
												color="blue darken-4 white--text"
												class=""
												:disabled="files.length > 4"
												tile
												depressed
												small
												min-width="30"
												width="30"
												v-on:click="addMore()"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</div>
									</div>
								</template>
							</v-col>
						</v-row>
						<v-row class="mb-3">
							<v-col md="3" class="text-end pt-2">
								<label class="fw-500">Description</label>
							</v-col>
							<v-col md="9" class="pt-0">
								<v-checkbox hide-details class="mt-0" v-model="meeting.isDesc"></v-checkbox>
								<ckeditor
									v-if="meeting.isDesc"
									:editor="editor"
									v-model="meeting.description"
									:config="editorConfig"
								></ckeditor>
								<!-- {{ meeting.description ? descriptionCount(meeting.description) : 0 }} -->
							</v-col>
						</v-row>
					</div>
				</v-form>
				<div style="min-height: 55px">
					<v-flex class="py-3 px-5 d-flex border-bottom drawer-footer">
						<v-spacer></v-spacer>
						<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4"
							:disabled="pageLoading || !formValid"
							v-on:click="update_or_create"
							>Save</v-btn
						>
					</v-flex>
				</div>
			</div>
			<CreateInternalLead
				v-if="CreateLeadDialog"
				v-on:success="CreateLeadDialog = false"
				v-on:refresh="refreshAlldata"
				v-on:close="CreateLeadDialog = false"
				:create-lead-dialog="CreateLeadDialog"
			>
			</CreateInternalLead>
		</v-navigation-drawer>
	</div>
</template>
<style scoped>
.v-dialog__content.v-dialog__content--active {
	z-index: 202 !important;
}
</style>
<script>
import MomentJS from "moment-timezone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { head, map, toSafeInteger, filter } from "lodash";
import { mapGetters } from "vuex";
import { MeetingEventBus } from "@/core/lib/meeting.form.lib";
import MainMixin from "@/core/mixins/main.mixin.js";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import TimePicker from "vue2-timepicker/src/vue-timepicker.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import CreateInternalLead from "@/view/pages/leads/create/Create-Internal-Lead";
import DateTimePicker from "@/view/components/DateTimePicker";
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import SearchAddress from "@/view/components/Search-Address";
import TimePickerNew from "@/view/components/TimePickerNew";

import objectPath from "object-path";

import { find } from "lodash";

export default {
	mixins: [MainMixin],
	name: "CreateMeeting",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		getMeetingData: {
			type: Object,
		},
		attendies: {
			type: Object,
		},
		related: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		leadData: {
			type: Number,
			default: 0,
		},
		titleStatus: {
			type: String,
		},
	},
	watch: {
		getMeetingData: {
			deep: true,
			handler(param) {
				if (param.remindersdata) {
					this.remindersdata = JSON.parse(param.remindersdata);
				}
				if (this.getMeetingData.id) {
					if (!this.updateMeetingType) {
						this.updateMeetingType = param?.meeting_type;
						this.defaultLocationUpdated = param?.location;
					}

					this.meeting.id = param.id;
					this.meeting.related_id = param ? toSafeInteger(param.related_id) : 0;
					this.meeting.related_to = param.related_to;
					// console.log(this.meeting.related_id,'param')

					/** Related assigned null from table edit button clicks so we assign here for related functionality work */
					if (this.related == null) {
						this.related = param.related_to ? param.related_to : null;
					}

					this.meeting.title = param.title;
					this.meeting.start_from = param.start_from;
					this.meeting.end_to = param.end_to;
					this.meeting.start_time = param.start_time;
					this.meeting.status = param.status;
					this.meeting.end_time = param.end_time;
					this.meeting.set_reminder = param.set_reminder;

					this.meeting.reminder_start_date =
						this.remindersdata && this.remindersdata[0].reminder_date
							? MomentJS(this.remindersdata[0].reminder_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
							: null;
					this.meeting.reminder_end_date =
						this.remindersdata && this.remindersdata[0].reminder_end_date
							? MomentJS(this.remindersdata[0].reminder_end_date, "YYYY-MM-DD HH:II:SS").format(
									"YYYY-MM-DD"
							  )
							: null;
					this.meeting.reminder_type =
						this.remindersdata && this.remindersdata[0].reminder_type
							? this.remindersdata[0].reminder_type
							: 1;
					this.meeting.reminder_frequency =
						this.remindersdata && this.remindersdata[0].reminder_frequency
							? this.remindersdata[0].reminder_frequency
							: 1;
					this.meeting.meeting_type = param.meeting_type;
					/* 	console.log(this.meeting.meeting_type,'watch function')  */

					//this.meeting.default_location_url = param.location;

					this.meeting.location = param.location;
					/* this.meeting.location = param.location; */
					this.meeting.longitude = param.longitude;

					this.meeting.latitude = param.latitude;
					this.meeting.duration = param.duration;
					this.meeting.description = param.description;
					this.meeting.mom = param.mom;
					this.meeting.lead_id = param.relatedId;
					this.meeting.attendees = param.attendies;
					this.files = [{ file: null, name: null }];
					/* alert(this.meeting.meeting_type); */
					if (this.titleStatus != "Update") this.getRelatedField(true);
					this.titleStatus = "Update";
				}
			},
		},
		attendies: {
			deep: true,
			immediate: true,
			handler(param) {
				//console.log(param?.attendies);
				this.meeting.attendees = map(param?.attendies, "id");
			},
		},
		/* related:{
			deep: true,
			immediate: true,
			handler(param) {
				if(this.param == 'task'){
				  this.meetin.related_to ='task'
		}
			},

		} */
	},
	components: {
		DatePicker,
		TextInput,
		AutoCompleteInput,
		CreateInternalLead,
		DateTimePicker,
		SearchAddress,
		RadioInputCustom,
		TimePicker,
		TimePickerNew,
	},
	data() {
		return {
			meetingOption: {
				label: null,
				task: {
					start_date: null,
					due_date: null,
					priority: null,
					related_to: null,
					lead_barcode: null,
					lead_company: null,
				},
				customer: { company: null, uen: null, barcode: null, website: null },
				lead: { company: null, email: null, phone: null },
				address: {
					postal_code: null,
					country: null,
					unit_number: null,
					address_1: null,
					address_2: null,
				},
			},
			priority_color: {
				high: " darken-4 orange",
				urgent: "red",
				low: "orange",
			},
			updateMeetingType: null,
			defaultLocationUpdated: null,
			reminderList: [
				{
					text: "10 mins before",
					value: "10",
				},
				{
					text: "20 mins before",
					value: "20",
				},
				{
					text: "30 mins before",
					value: "30",
				},
				{
					text: "Others",
					value: "others",
				},
			],
			countryList: [
				{
					text: "Singapore",
					value: "singapore",
					country_code: "sg",
				},
				{
					text: "Malaysia",
					value: "malaysia",
					country_code: "my",
				},
				{
					text: "Myanmar",
					value: "myanmar",
					country_code: "mm",
				},
				{
					text: "India",
					value: "india",
					country_code: "in",
				},
				{
					text: "China",
					value: "china",
					country_code: "cn",
				},
				{
					text: "Australia",
					value: "australia",
					country_code: "at",
				},
				{
					text: "American",
					value: "american",
					country_code: "as",
				},
				{
					text: "UK",
					value: "uk",
					country_code: "gb",
				},
				{
					text: "Canada",
					value: "canada",
					country_code: "ca",
				},
			],
			country: "singapore",
			country_code: "sg",
			reminderEndFormatted: null,
			pageLoading: false,
			formValid: true,
			editor: ClassicEditor,
			editorData: "<p>Description</p>",
			editorMom: "<p>MOM</p>",
			editorConfig: {
				// The configuration of the editor.
			},
			timeSlots: [],
			CreateLeadDialog: false,
			companyInfo: {},
			addrssList: [],
			addrssCustomerList: [],
			addrssTaskList: [],
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				// { value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Others" },
				// { value: 4, text: "Monthly Reminder" },
				// { value: 5, text: "Quarterly Reminder" },
				// { value: 6, text: "Half Yearly Reminder" },
			],
			meeting: {
				title: null,
				start_from: new Date().toISOString().substr(0, 10),
				start_time: "08:30",
				meeting_type: null,
				end_to: null,
				end_time: null,
				location: null,
				latitude: null,
				longitude: null,
				pre_location: null,
				mom: null,
				duration: "30 Min",
				description: null,
				isDesc: false,
				reminder_start_date: this.formatDateTimeRaw(new Date()),
				// reminder_start_time: null,
				// reminder_end_time: null,
				reminder_end_date: new Date().toISOString().substr(0, 10),
				reminder_type: 1,
				callType: "other",
				reminder_frequency: 1,
				related_to: "lead",
				related_id: 0,
				attendees: [],
				lead_id: 0,
				status: 0,
				set_reminder: null,
				default_location_url: null,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
			userList: [],
			durationList: [],
			timeList: [],
			dataLists: [],
			relatedList: [
				{
					label: "Opportunity",
					value: "lead",
				},
				// {
				// 	label: "Contact",
				// 	value: "contact",
				// },
				{
					label: "Compnay",
					value: "lead",
				},
				// {
				// 	label: "Opportunity",
				// 	value: "opportunity",
				// },
				{
					label: "Task",
					value: "task",
				},
				{
					label: "Others",
					value: "others",
				},
				// {
				// 	label: "Lead",
				// 	value: "lead",
				// },
				{
					label: "Company",
					value: "customer",
				},
				// {
				// 	label: "Task",
				// 	value: "task",
				// },
				// {
				// 	label: "Internal",
				// 	value: "internal",
				// },
			],
			InternalMeetingTypeList: [
				{
					label: "F2F Client Office",
					img: null,
					value: "client",
				},
				{
					label: "F2F BThrust Office",
					img: this.$assetURL("media/project-logos/bthrust-icon.png"),
					value: "bthrust",
				},
				{
					label: "Zoom/Online",
					img: null,
					value: "other",
				},
				{
					label: "Other Location",
					img: null,
					value: "other2",
				},
			],
			client_address: null,
		};
	},
	methods: {
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		descriptionCount(text) {
			var regex = /(<([^>]+)>)/gi,
				result = text.replace(regex, "");
			return result.length;
		},
		countryCheck(data) {
			let code = "";
			code = find(this.countryList, function (row) {
				//console.log(row);
				if (row.value == data) {
					return row.country_code;
				} else {
					return false;
				}
			});
			this.country_code = code.country_code;
			this.country = data;
			this.meeting.location = null;
		},
		setAddress(e) {
			let meetingAddress = "";
			meetingAddress += e.address1;
			meetingAddress += e.address2;
			meetingAddress += e.unit_number && e.unit_number != "null" ? e.unit_number : "";
			meetingAddress += e.postal_code && e.postal_code != "null" ? e.postal_code : "";
			this.meeting.location = meetingAddress;
		},
		// validateCharactors(string=''){
		// 	console.log(string);
		// 	if(string.length() > 200){
		// 		return false;
		// 	}
		// },
		refreshAlldata() {
			if (this.meeting.meeting_type != "bthrust") {
				this.getRelatedField(true);
			}
		},
		get_reminder_values() {
			/*if(!this.meeting.reminder_frequency){
				this.meeting.reminder_frequency = 1;
			}*/

			let reminderParseDate = MomentJS(this.meeting.reminder_start_date);
			/* let test = MomentJS(reminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */
			if (this.meeting.reminder_start_date) {
				for (var i = 0; i < this.meeting.reminder_frequency; i++) {
					if (this.meeting.reminder_type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(6, "months");
					}

					if (this.meeting.reminder_type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(3, "months");
					}

					if (this.meeting.reminder_type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(1, "months");
					}

					if (this.meeting.reminder_type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(7, "days");
					}

					if (this.meeting.reminder_type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(1, "days");
					}

					if (this.meeting.reminder_type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:II:SS").add(7, "days");
					}
				}

				this.reminderEndFormatted = reminderParseDate.format("DD/MM/YYYY HH:mm");

				return (this.meeting.reminder_end_date = reminderParseDate.format("YYYY-MM-DD HH:mm"));
			}
		},
		meetingLocation(value) {
			let taskData = find(this.dataLists, (row) => row.value == value);

			console.log({ taskData });

			if (this.meeting.related_to == "customer" || this.related == "customer") {
				const firstEle = find(this.addrssCustomerList, { value: value });

				if (firstEle && firstEle.value) {
					if (this.relatedId > 0) {
						this.meeting.related_id = value;
						/* this.meeting.location = concat([firstEle.address_line_1, firstEle.address_line_2, firstEle.unit_number, firstEle.country, firstEle.postal_code]) */
					} else {
						this.meeting.related_id = value;
						/* this.meeting.location = concat([firstEle.address_line_1, firstEle.address_line_2, firstEle.unit_number, firstEle.country, firstEle.postal_code]); */
					}
					if (this.meeting.meeting_type == "client") {
						let addressArr = [
							firstEle.address_line_1,
							firstEle.address_line_2,
							firstEle.unit_number,
							firstEle.country,
							firstEle.postal_code,
						];
						addressArr = filter(addressArr, (row) => row && row != undefined);
						this.meeting.location = addressArr.join(", ");
						this.meeting.pre_location = addressArr.join(", ");
					}
					//set option

					this.setMeetingOptions(firstEle, "customer");
					//this.meetingOption.customer = {company:firstEle?.company_name,uen:firstEle?.company_uen,barcode:firstEle?.barcode,website:firstEle?.website};
					//this.meetingOption.address = {postal_code:firstEle?.postal_code,country:firstEle?.country,unit_number:firstEle?.unit_number,address_1:firstEle?.address_line_1,address_2:firstEle?.address_line_2};
				}
			}
			if (this.meeting.related_to == "lead" || this.related == "lead") {
				const firstEle = find(this.addrssList, { value: value });

				if (firstEle && firstEle.value) {
					if (this.relatedId > 0) {
						this.meeting.related_id = value;
						//this.meeting.default_location_url = this.meeting.location;
					} else {
						this.meeting.related_id = value;
						/* this.meeting.location = concat([firstEle.address_line_1, firstEle.address_line_2, firstEle.unit_number, firstEle.country, firstEle.postal_code]); */
						//this.meeting.default_location_url = this.meeting.location;
					}
					if (this.meeting.meeting_type == "client") {
						let addressArr = [
							firstEle.address_line_1,
							firstEle.address_line_2,
							firstEle.unit_number,
							firstEle.country,
							firstEle.postal_code,
						];
						addressArr = filter(addressArr, (row) => row && row != undefined);
						this.meeting.location = addressArr.join(", ");
						this.meeting.pre_location = addressArr.join(", ");
					}
				}

				//set option
				this.setMeetingOptions(firstEle, "lead");
				//this.meetingOption.lead = {company:firstEle?.company_name,email:firstEle?.email,phone:firstEle?.phone};
				//this.meetingOption.address = {postal_code:firstEle?.postal_code,country:firstEle?.country,unit_number:firstEle?.unit_number,address_1:firstEle?.address_line_1,address_2:firstEle?.address_line_2};
			}
			if (this.meeting.related_to == "task" || this.related == "task") {
				let taskData = find(this.dataLists, (row) => row.value == this.meeting.related_id);
				const firstEle = find(this.addrssTaskList, {
					value: this.leadData ? this.leadData : taskData?.leadId,
				});

				if (firstEle && firstEle.value) {
					if (this.relatedId > 0) {
						this.meeting.related_id = value;
						/* this.meeting.location = concat([firstEle.address_line_1, firstEle.address_line_2, firstEle.unit_number, firstEle.country, firstEle.postal_code]) */
					} else {
						this.meeting.related_id = value;
						/* this.meeting.location = concat([firstEle.address_line_1, firstEle.address_line_2, firstEle.unit_number, firstEle.country, firstEle.postal_code]); */
					}
					if (this.meeting.meeting_type == "client") {
						let addressArr = [
							firstEle.address_line_1,
							firstEle.address_line_2,
							firstEle.unit_number,
							firstEle.country,
							firstEle.postal_code,
						];
						addressArr = filter(addressArr, (row) => row && row != undefined);
						this.meeting.location = addressArr.join(", ");
						this.meeting.pre_location = addressArr.join(", ");
					}
				} else {
					if (this.meeting.meeting_type == "client") {
						this.meeting.location = null;
						this.meeting.pre_location = null;
					}
				}

				this.setMeetingOptions(taskData, "task");
			}

			if (this.meeting.related_to == "internal" || this.related == "internal") {
				this.setMeetingOptions("", "internal");
			}

			/* const chnageCustomer = find(this.addrssCustomerList, { value: value });
			console.log(chnageCustomer,'chnageCustomer') */
			/* this.meeting.location = value; */
		},
		getCompanySetting(type) {
			if (type == "bthrust") {
				this.$store
					.dispatch(GET, { url: `company-setting/1` })
					.then((data) => {
						this.companyInfo = data;
						let addressArr = [
							this.companyInfo.address,
							this.companyInfo.postal_code,
							this.companyInfo.country,
						];
						addressArr =
							filter(addressArr, (row) => row && row != undefined) || addressArr.filter(Boolean);
						this.meeting.location = addressArr.join(", ");
					})
					.catch((error) => {
						console.log({ error });
					});
			}

			if (type == "client") {
				if (this.meeting.related_to == "lead" || this.related == "leads") {
					//console.log(this.meeting);
					//console.log(this.meeting.related_id,'related id');
					const firstEle = find(this.addrssList, { value: this.meeting.related_id });
					if (firstEle && firstEle.value) {
						// if (this.relatedId > 0) {
						this.meeting.related_id = this.relatedId > 0 ? this.relatedId : this.meeting.related_id;
						console.log(this.meeting.related_id, "this.meeting.related_id");

						// }
						if (this.meeting.meeting_type == "client") {
							let addressArr = [
								firstEle.address_line_1,
								firstEle.address_line_2,
								firstEle.unit_number,
								firstEle.country,
								firstEle.postal_code,
							];
							addressArr =
								filter(addressArr, (row) => row && row != undefined) || addressArr.filter(Boolean);
							this.meeting.location = addressArr.join(", ");
							this.meeting.pre_location = addressArr.join(", ");
						}
					}
					//added new

					//console.log(this.meeting.default_location_url);

					if (this.meeting.id && !this.meeting.default_location_url) {
						this.meeting.default_location_url = this.meeting.location;
					}
					//console.log(this.meeting.default_location_url);
				}
				if (this.meeting.related_to == "customer" || this.related == "customer") {
					const firstEle = find(this.addrssCustomerList, { value: this.meeting.related_id });
					if (firstEle && firstEle.value) {
						if (this.relatedId > 0) {
							this.meeting.related_id = this.relatedId;
						}
						console.log(this.meeting.related_id, "this.meeting.related_id");

						if (this.meeting.meeting_type == "client") {
							let addressArr = [
								firstEle.address_line_1,
								firstEle.address_line_2,
								firstEle.unit_number,
								firstEle.country,
								firstEle.postal_code,
							];
							addressArr =
								filter(addressArr, (row) => row && row != undefined) || addressArr.filter(Boolean);
							this.meeting.location = addressArr.join(", ");
							this.meeting.pre_location = addressArr.join(", ");
						}
					}
				}
				if (this.meeting.related_to == "task" || this.related == "task") {
					let taskData = find(this.dataLists, (row) => row.value == this.meeting.related_id);
					const firstEle = find(this.addrssTaskList, {
						value: this.leadData ? this.leadData : taskData.leadId,
					});
					if (firstEle && firstEle.id) {
						if (this.relatedId > 0) {
							this.meeting.related_id = this.relatedId;
						} else {
							this.meeting.related_id = firstEle.id;
						}
						console.log(this.meeting.related_id, "this.meeting.related_id");
						if (this.meeting.meeting_type == "client") {
							let addressArr = [
								firstEle.address_line_1,
								firstEle.address_line_2,
								firstEle.unit_number,
								firstEle.country,
								firstEle.postal_code,
							];
							addressArr =
								filter(addressArr, (row) => row && row != undefined) || addressArr.filter(Boolean);
							this.meeting.location = addressArr.join(", ");
							this.meeting.pre_location = addressArr.join(", ");
						}
					} else {
						if (this.meeting.meeting_type == "client") {
							this.meeting.location = null;
							this.meeting.pre_location = null;
						}
					}
				}
			}

			if (type == "other" || type == "other2") {
				//this.meeting.location = null;
				if (!this.meeting.id) {
					//|| (this.meeting.type!='other' && this.meeting.type!='other2')
					this.meeting.location = null;
				} else {
					//this.updateMeetingType
					if (this.updateMeetingType != type) this.meeting.location = null;
					else this.meeting.location = this.defaultLocationUpdated;
				}
			}
		},
		getMeetingTypeData(type) {
			alert(5656);
			if (type == "client") {
				this.getRelatedField(false);
			} else {
				this.getCompanySetting(type);
			}
		},
		durationTimedate() {
			if (this.meeting.duration && this.meeting.start_from && this.meeting.start_time) {
				let number = this.meeting.duration.split(" ")[0];
				let string = this.meeting.duration.split(" ")[1];

				if (string == "Min") {
					string = "minutes";
				} else {
					string = "hour";
				}

				let datetimeA = MomentJS(this.meeting.start_from + " " + this.meeting.start_time);
				let datetimeb = MomentJS(this.meeting.start_from + " " + this.meeting.start_time);

				/* if(this.meeting.end_to == datetimeA.add(number, string).format("YYYY-MM-DD")){ */

				//if (!this.meeting.end_to) {
				this.meeting.end_to = datetimeA.add(number, string).format("YYYY-MM-DD");
				//}
				/* } */
				/* if(this.meeting.end_time == datetimeb.add(number, string).format("HH:mm")){
				 */

				this.meeting.end_time = datetimeb.add(number, string).format("HH:mm");

				// if(this.meeting.start_time=="22:30" && (this.meeting.end_time=="00:00")){
				// 	this.meeting.end_to = datetimeA.add(1, "d").format("YYYY-MM-DD");
				// }

				/* } */

				/*if (this.meeting.end_time > MomentJS("2022-12-22 20:00").format("HH:mm")) {
					this.$store.commit(SET_ERROR, [{ model: true, message: `Please Enter Valid Time.` }]);
					this.meeting.start_from = "";
					this.meeting.start_time = "";
					this.meeting.end_to = "";
					this.meeting.end_time = "";
				}*/
			}
		},
		remove(item) {
			if (this.currentUser.role == 1 || this.currentUser.role == 4) {
				const index = this.meeting.attendees.indexOf(item.id);
				this.meeting.attendees.splice(index, 1);
			}
		},
		setMeetingOptions(optionData = null, type = "") {
			switch (type) {
				case "lead":
					this.meetingOption.lead = {
						company: optionData?.company_name,
						email: optionData?.email,
						phone: optionData?.phone,
					};

					this.meetingOption.address = {
						postal_code: optionData?.postal_code,
						country: optionData?.country,
						unit_number: optionData?.unit_number,
						address_1: optionData?.address_line_1,
						address_2: optionData?.address_line_2,
					};
					break;
				case "customer":
					this.meetingOption.customer = {
						company: optionData?.company_name,
						uen: optionData?.company_uen,
						barcode: optionData?.barcode,
						website: optionData?.website,
					};
					this.meetingOption.address = {
						postal_code: optionData?.postal_code,
						country: optionData?.country,
						unit_number: optionData?.unit_number,
						address_1: optionData?.address_line_1,
						address_2: optionData?.address_line_2,
					};
					break;
				case "task":
					(this.meetingOption.task = {
						start_date: optionData?.start_date,
						due_date: optionData?.due_date,
						priority: optionData?.priority,
						related_to: optionData?.related_to,
						lead_barcode: optionData?.lead_barcode,
						lead_company: optionData?.lead_company,
					}),
						(this.meetingOption.address = {
							postal_code: null,
							country: null,
							unit_number: null,
							address_1: null,
							address_2: null,
						});
					break;
				case "internal":
					this.meetingOption.label = null;
					break;
			}
		},
		getRelatedField(param) {
			this.pageLoading = true;
			this.dataLists = []; // listing eg: Company Name, Customer, Task
			this.$store
				.dispatch(GET, { url: `related-field` })
				.then((data) => {
					if (this.meeting.related_to !== "others") {
						this.dataLists = data[this.meeting.related_to];
					}
					// check if exists
					// this.meeting.related_id = 0;

					if (this.meeting.related_id < 1) {
						if (this.titleStatus == "Create" && this.dataLists.length) {
							const relatedData = head(this.dataLists) || this.dataLists[0];
							this.meeting.related_id = objectPath.get(relatedData, "value")
								? objectPath.get(relatedData, "value")
								: this.meeting.related_id;
						}
						if (this.titleStatus == "Update") {
							const relatedData = find(this.dataLists, { value: this.relatedId });
							// this.dataLists.find(li => li.value == this.relatedId)
							this.meeting.related_id = objectPath.get(relatedData, "value")
								? objectPath.get(relatedData, "value")
								: this.meeting.related_id;
						}
					}

					this.addrssList = data.lead_address;
					this.addrssCustomerList = data.customer_address;
					console.log(data.customer_address, "data.customer_address");
					this.addrssTaskList = data.task_lead_address;
					const tasksData = data.task;
					this.meetingOption.label = this.meeting.related_to;

					// set options
					if (this.meeting.related_id > 0 && this.meeting.related_to) {
						const firstEle1 = find(this.addrssList, { value: this.meeting.related_id });
						const firstEle2 = find(this.addrssCustomerList, { value: this.meeting.related_id });
						const firstEle3 = find(tasksData, { value: this.meeting.related_id });

						switch (this.meeting.related_to) {
							case "lead":
								if (firstEle1) this.setMeetingOptions(firstEle1, "lead");
								break;
							case "customer":
								if (firstEle2) this.setMeetingOptions(firstEle2, "customer");
								break;
							case "internal":
								this.setMeetingOptions("", "internal");
								break;
							case "task":
								if (firstEle3) this.setMeetingOptions(firstEle3, "task");
								break;
						}
					} else {
						//set address null if no data available
						this.meetingOption.address = {};
						this.meetingOption.label = null;
					}
					console.log(this.meeting.related_id, "this.meeting.related_id");

					if (this.meeting.location) {
						// && this.meeting.related_id
						// this.meeting.location = null;
						if ((!param && this.meeting.related_to == "task") || this.related == "task") {
							const firstEle = this.leadData
								? find(this.addrssTaskList, { value: this.leadData })
								: find(this.addrssTaskList, { value: this.relatedId });
							if (firstEle && firstEle.id) {
								if (this.relatedId > 0) {
									this.meeting.related_id = this.relatedId;
									/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										this.meeting.pre_location = addressArr.join(", "); */
								} else {
									this.meeting.related_id = firstEle.id;
									/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										this.meeting.pre_location = addressArr.join(", "); */
								}
							} /*  else {
								this.meeting.location = null;
								this.meeting.pre_location = null;
							}*/
							//this.setMeetingOptions(firstEle,'task');
						} else {
							//console.log('clicked',this.meeting.related_to);
							if ((!param && this.meeting.related_to == "lead") || this.related == "leads") {
								const firstEle = find(this.addrssList, { value: this.relatedId });

								if (firstEle && firstEle.value) {
									if (this.relatedId > 0) {
										this.meeting.related_id = this.relatedId;
										/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										this.meeting.pre_location = addressArr.join(", "); */
										//this.default_location_url = this.meeting.location;
									} else {
										this.meeting.related_id = firstEle.value;
										/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										*/
										//this.default_location_url = this.meeting.location;
									}
								}
							}

							if ((!param && this.meeting.related_to == "customer") || this.related == "customer") {
								const firstEle = find(this.addrssCustomerList, { value: this.relatedId });
								if (firstEle && firstEle.value) {
									if (this.relatedId > 0) {
										this.meeting.related_id = this.relatedId;
									} else {
										this.meeting.related_id = firstEle.value;
									}

									/* let addressArr = [
										firstEle.address_line_1,
										firstEle.address_line_2,
										firstEle.unit_number,
										firstEle.country,
										firstEle.postal_code,
									];
									addressArr = filter(addressArr, (row) => row && row != undefined);
									this.meeting.location = addressArr.join(", ");
									this.meeting.pre_location = addressArr.join(", "); */
								}
							}
							if ((!param && this.meeting.related_to == "task") || this.related == "task") {
								const firstEle = this.leadData
									? find(this.addrssTaskList, { value: this.leadData })
									: head(this.addrssTaskList);
								if (firstEle && firstEle.id) {
									if (this.relatedId > 0) {
										this.meeting.related_id = this.relatedId;
										/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										this.meeting.pre_location = addressArr.join(", "); */
									} else {
										this.meeting.related_id = firstEle.id;
										/* let addressArr = [
											firstEle.address_line_1,
											firstEle.address_line_2,
											firstEle.unit_number,
											firstEle.country,
											firstEle.postal_code,
										];
										addressArr = filter(addressArr, (row) => row && row != undefined);
										this.meeting.location = addressArr.join(", ");
										this.meeting.pre_location = addressArr.join(", "); */
									}
									//this.setMeetingOptions(firstEle,'task');
								} else {
									this.meeting.related_id = this.relatedId ? this.relatedId : firstEle.id;
									/* this.meeting.location = null;
									this.meeting.pre_location = null; */
								}
							}
						}
						if (this.meeting.meeting_type == "client") {
							//this.meeting.location = this.getMeetingData.location;
							//this.meeting.pre_location = this.getMeetingData.location;
						}
					}

					if (!this.meeting.id) {
						this.getCompanySetting("client"); // patched by shubham
					} else {
						let meetType = this.meeting.meeting_type ? this.meeting.meeting_type : "client";
						this.getCompanySetting(meetType);
					}
				})

				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getMeetingSetting() {
			this.$store
				.dispatch(GET, { url: `meeting-setting` })
				.then((data) => {
					/*
						not in use
						this.timeList = data.time;
					*/
					this.timeList = data.time;
					this.durationList = data.duration;
					this.userList = data.users.filter((user) => user.users_type == "sales");
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["meetingForm"]);
			const _this = this;

			this.$refs["meetingForm"].validate();

			if (formErrors?.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["meetingForm"].validate()) {
				return false;
			}

			this.meeting[this.meeting.related_to] = this.meeting.related_id;

			this.pageLoading = true;
			let formData = new FormData();

			formData.append("title", this.meeting.title ? this.meeting.title : null);
			formData.append("start_from", this.meeting.start_from ? this.meeting.start_from : null);
			formData.append("start_time", this.meeting.start_time ? this.meeting.start_time : null);
			formData.append("meeting_type", this.meeting.meeting_type ? this.meeting.meeting_type : null);
			formData.append("end_to", this.meeting.end_to ? this.meeting.end_to : null);
			formData.append("end_time", this.meeting.end_time ? this.meeting.end_time : null);
			if (this.meeting.set_reminder) {
				formData.append("set_reminder", this.meeting.set_reminder ? this.meeting.set_reminder : null);
			}

			if (this.meeting && this.meeting.reminder_start_date) {
				formData.append(
					"reminder_start_date",
					this.meeting.reminder_start_date ? this.meeting.reminder_start_date : null
				);
			}
			if (this.meeting && this.meeting.reminder_end_date && this.meeting.reminder_type != 1) {
				formData.append(
					"reminder_end_date",
					this.meeting.reminder_end_date ? this.meeting.reminder_end_date : null
				);
			}
			if (this.meeting && this.meeting.callType) {
				formData.append("callType", this.meeting.callType ? this.meeting.callType : "other");
			}
			if (this.meeting && this.meeting.reminder_type) {
				formData.append("reminder_type", this.meeting.reminder_type ? this.meeting.reminder_type : 1);
			}
			/* if (this.meeting && this.meeting.reminder_frequency) { */
			formData.append(
				"reminder_frequency",
				this.meeting.reminder_frequency ? this.meeting.reminder_frequency : 1
			);
			/* } */
			if (this.meeting && this.meeting.mom) {
				formData.append("mom", this.meeting.mom ? this.meeting.mom : null);
			}
			if (this.meeting && this.meeting.location) {
				formData.append("location", this.meeting.location ? this.meeting.location : null);
			}
			/* if (this.meeting && this.meeting.location) {
				formData.append("location", this.meeting.location ? this.meeting.location : 0);
			} */
			if (this.meeting && this.meeting.latitude) {
				formData.append("latitude", this.meeting.latitude ? this.meeting.latitude : null);
			}
			if (this.meeting && this.meeting.longitude) {
				formData.append("longitude", this.meeting.longitude ? this.meeting.longitude : null);
			}
			if (this.meeting && this.meeting.mom) {
				formData.append("mom", this.meeting.mom ? this.meeting.mom : null);
			}

			// if(this.meeting.description.length > 200) {
			// 	this.$store.commit(SET_ERROR, [{ model: true, message: `Description must be less than 200 charactors.` }]);
			// }

			formData.append("duration", this.meeting.duration ? this.meeting.duration : null);
			if (this.meeting && this.meeting.description) {
				formData.append("description", this.meeting.description ? this.meeting.description : null);
			}
			if (this.meeting && this.meeting.status) {
				formData.append("status", this.meeting.status ? this.meeting.status : 4);
			}
			formData.append("related_to", this.meeting.related_to ? this.meeting.related_to : this.related);
			console.log({ param: this.meeting.related_id, param2: this.relatedId });
			formData.append(
				"related_id",
				this.meeting.related_id ? this.meeting.related_id : this.relatedId
			);
			if (this.country) {
				formData.append("country", this.country);
			}
			formData.append("lead_id", this.meeting.lead_id > 0 ? this.meeting.related_id : 0);
			for (let i = 0; i < this.files?.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}
			for (let i = 0; i < this.meeting?.attendees?.length; i++) {
				if (this.meeting && this.meeting.attendees && this.meeting.attendees[i]) {
					formData.append(`attendees[${i}]`, this.meeting.attendees[i]);
				}
			}
			let msg = "Success ! Meeting Created successfully.";
			let requestTYPE = POST;
			let requestURL = "meeting";

			if (this.getMeetingData && this.getMeetingData.id) {
				requestURL = `meeting/${this.getMeetingData.id}`;
				msg = "Success ! Meeting Updated  successfully.";
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [{ model: true, message: msg }]);
					_this.$refs.meetingForm.reset();
					_this.description = "";
					_this.mom = "";
					MeetingEventBus.$emit("refresh", true);
					_this.$emit("updateMeetingSuccess", true);
					_this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files?.length < 1) {
				this.addMore();
			}
		},
		initTimeList() {
			this.timeSlots = [];
			let startTime = MomentJS().startOf("day"); // 00:00
			let endTime = MomentJS().endOf("day"); // 23:59

			while (startTime.isBefore(endTime)) {
				let text = startTime.format("hh:mm A"); // 12-hour format with AM/PM
				let value = startTime.format("HH:mm"); // 24-hour format
				this.timeSlots.push({ text, value });
				startTime.add(30, "minutes");
			}

			/* this.timeSlots = [];
			let startTime = MomentJS().startOf("day");
			let endTime = MomentJS().endOf("day");
			let value = startTime.format("hh:mm A");
			while (startTime.isBefore(endTime)) {
				this.timeSlots.push({ text: value, value });
				value = startTime.add(15, "minutes").format("hh:mm A");
			} */
		},
	},

	computed: {
		formattedDate() {
			const options = { year: "numeric", month: "long", day: "numeric" };
			return new Date(this.meeting.start_from).toLocaleDateString("en-US", options);
		},
		...mapGetters(["errors"]),
		filteredArray() {
			if (this.userList && this.userList?.length > 0) {
				return this.userList.filter((user) => this.meeting.attendees.includes(user.id));
			} else {
				return [];
			}
		},
	},
	mounted() {
		if (this.related) {
			this.meeting.related_to = "lead";
			if (this.related == "task") {
				this.meeting.related_to = "task";
			}
			if (this.related == "customer") {
				this.meeting.related_to = "customer";
			}
		}

		this.getMeetingSetting();

		if (this.titleStatus != "Update") {
			this.meeting.meeting_type = "client"; // Assign client and change event fires > getMeetingTypeData > this.getRelatedField
			this.getRelatedField(false);
			/* this.userId => Login user id */
			this.meeting.attendees = [this.userId];
		}

		this.getCompanySetting("client");
		this.initTimeList();
		this.meeting.callType = "other";
	},
	beforeMount() {
		this.lead_id = this.$route.params.id || null;
	},
};
</script>
